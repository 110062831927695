<template>
  <div class="SocialAccount">
    <div class="searchBox">
      <!-- 搜索 -->
      <search-input
        class="searchInput"
        ref="searchInput"
        :placeholder="$t('lang.i18n_outputName')"
        :callback="search"
        :value="search_key"></search-input>
      <!-- 下拉多选组件 -->
      <div class="box set width200">
        <drop-menu-select
          :data="typeSelect"
          :typeVal='"i18n_websiteType"'
          :title="$t('lang.i18n_websiteType')"
          :callback="handleOptionsChange"></drop-menu-select>
      </div>
      <el-dropdown
        @command="addorEditAccount"
        trigger="click"
        placement="bottom-start">
        <span class="el-dropdown-link">
          <i class="icon_plus iconfont i-add"></i>
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="showPop">
          <el-dropdown-item
            v-for="(item, index) in typeSelect"
            :key="index"
            v-if="index != 0 && item.show"
            :command="item.key">
            {{$t(`lang.${item.label}`)}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 分页组件 -->
      <Pagination
        v-if="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :pageSizes="[10, 20, 30, 50]"
        :pageSize="pageSize"
        :total="total"
        layout="prev, pageF, next, pageL, sizes, nowSize, jumper" />
    </div>
    <el-table
      v-loading="loading"
      element-loading-background="transparent"
      class="listTable"
      :data="tableData">
      <el-table-column
        :label="$t('lang.i18n_websiteType')"
        max-width="312px">
        <template slot-scope="scope">
          <span
            class="iconfont wesite_logo active"
            v-if="scope.row.sharing">
            <svg
              class="svgicon"
              aria-hidden="true">
              <use
                v-if="scope.row.type == 'Twitter'"
                xlink:href="#icon-Twitter"></use>
              <use
                v-else-if="scope.row.type == 'F'"
                xlink:href="#icon-facebook2"></use>
              <use
                v-else-if="scope.row.type == 'Y'&& scope.row.authType == '1'"
                xlink:href="#icon-youtubeautored"></use>
              <use
                v-else-if="scope.row.type == 'Y'&& scope.row.authType == '0'"
                xlink:href="#icon-youtubemanualred"></use>
              <use
                v-else-if="scope.row.type == 'Z'"
                xlink:href="#icon-yizhibo"></use>
              <use
                v-else-if="scope.row.type == 'O'"
                xlink:href="#icon-Rtmpgreen"></use>
              <use
                v-else-if="scope.row.type == 'Vimeo'"
                xlink:href="#icon-vimeo1"></use>
              <use
                v-else-if="scope.row.type == 'T'"
                xlink:href="#icon-twitch"></use>
              <use
                v-else-if="scope.row.type == 'I'"
                xlink:href="#icon-instagram2"></use>
              <use
                v-else-if="scope.row.type == 'K'"
                xlink:href="#icon-kuaishou3"></use>
              <use
                v-else
                xlink:href="#icon-Rtmpgreen"></use>
            </svg>
          </span>
          <span
            class="iconfont wesite_logo "
            v-else
            :class="{
              'i-facebook11': scope.row.type == 'F',
              'i-twitter': scope.row.type == 'Twitter',
              'i-youtubeAuto': scope.row.type == 'Y' && scope.row.authType == '0',
              'i-youtubeManu': scope.row.type == 'Y' && scope.row.authType == '1',
              'i-other': scope.row.type == 'O',
              'i-vimeo': scope.row.type == 'Vimeo',
              'i-yingke': scope.row.type == 'I',
              'i-yizhibo': scope.row.type == 'Z',
              'i-twitch': scope.row.type == 'T',
              'i-instagram': scope.row.type == 'I',
              'i-kuaishou': scope.row.type == 'K'
            }">
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('lang.accountURL')"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.type == 'Y' && scope.row.authType == '1'">{{ scope.row.detail.rtmpUrl}}</span>
          <span v-else-if="scope.row.type == 'Y' && scope.row.authType == '0'">{{scope.row.detail.ytbUserName}}</span>
          <span v-else-if="scope.row.type == 'F'">{{scope.row.detail.fbUserName}}</span>
          <span v-else-if="scope.row.type == 'Z'">{{scope.row.detail.yizhiboUserName}}</span>
          <span v-else-if="scope.row.type == 'Twitter'">{{scope.row.detail.rtmpUrl}}</span>
          <span v-else-if="scope.row.type == 'T'">{{scope.row.detail.twUserName}}</span>
          <span v-else-if="scope.row.type == 'I'">{{scope.row.detail.insUsername}}</span>
          <span v-else-if="scope.row.type == 'K'">{{scope.row.detail.kuaishouUserName}}</span>
          <span v-else-if="scope.row.type == 'O' || scope.row.type == 'Vimeo'">{{scope.row.detail.rtmpUrl?scope.row.detail.rtmpUrl:scope.row.nickName}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.i18n_outputName')">
        <template slot-scope="scope">
          <span>{{ scope.row.nickName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="debuggerFlag"
        label="模式">
        <template slot-scope="scope">
          <span v-if="scope.row.authType == '0' || scope.row.authType == null">自动</span>
          <span v-else>手动</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.i18n_ifSharing')">
        <template slot-scope="scope">
          <span v-if="scope.row.sharing">{{$t('lang.i18n_yes')}}</span>
          <span v-else>{{$t('lang.i18n_no')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.Resolution')">
        <template slot-scope="scope">
          <span>{{ scope.row.resolution }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.bit')">
        <template slot-scope="scope">
          <span>{{ scope.row.bitrate | BITRATE }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('lang.i18n_operate')"
        width="130px">
        <template slot-scope="scope">
          <p
            class="iconBox"
            v-if="!scope.row.sharing">
            <span
              class="iconfont i-edit"
              :title="$t('lang.edit')"
              @click="addorEditAccount(scope.row)"></span>

            <span
              class="iconfont i-delete"
              :title="$t('lang.Delete')"
              @click="deleteData(scope.row.id)"></span>
          </p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { SocialType } from '@/config'
import Pagination from '@/components/Pagination'
import SearchInput from '@/components/Search'
import DropMenuSelect from '@/components/dropMenuSelect'
export default {
  name: 'SocialAccount',
  props: {
    debuggerFlag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      typeSelect: [],
      // 分页组件
      currentPage: 1,
      pageSize: 10,
      total: 0,
      // 搜索字段
      search_key: '',
      search_type: this.$store.state.common.baseUrl.mediaControl.demandPlatform,
      // 下拉多选框组件
      defaultCheckAll: true,
      tableData: [],
      loading: false,
      upDataInterval: null
    }
  },
  created () {
    this.upDataInterval = setInterval(() => {
      this.upDataStatus()
    }, 5000)
    SocialType.forEach((itm, idx) => {
      itm.isChecked = true
      itm.show = this.search_type.includes(itm.key)
      if (itm.show || itm.key == 'all') {
        this.typeSelect.push(itm)
      }
    })
    this.getTableList()
  },
  mounted () {
    mediaObj.updataSocial = this.getTableList
  },
  methods: {
    search (val) {
      let regEn = /[`~!@#$%^&*()+<>?:"{},.\\/;'[\]]/im
      let regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\\]]/im
      if (regEn.test(val) || regCn.test(val)) {
        this.$message.info(this.$t('lang.noChart'))
        return
      }
      this.search_key = val
      this.currentPage = 1
      this.getTableList()
    },
    upDataStatus () {
      const arr = JSON.parse(JSON.stringify(this.tableData))
      for (let i = 0; i < arr.length; i++) {
        let nickN = arr[i].id
        let obj = arr[i]
        if (mediaObj.sharingSocial.indexOf(nickN) >= 0) {
          obj.sharing = true
        } else {
          obj.sharing = false
        }
        this.$set(this.tableData, i, obj)
      }
    },
    getTableList (arr) {
      this.loading = true
      let params = {
        nickName: this.search_key || '',
        type: this.search_type, // 默认空表示搜所有类型,NULL表示未选中任何类型
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        authType: '',
        detail: true
      }
      // 获取列表数据的公共方法
      SM.__proto__.socialReq(params).then(res => {
        if (res.data.errorCode == '0x0') {
          let data = res.data.result
          this.tableData = data.list
          this.total = data.total
          this.currentPage = data.pageNum
          this.upDataStatus()
          this.loading = false
        }
      }).catch(() => {})
    },
    // 添加、修改社交账号
    addorEditAccount (data) {
      if (typeof (data) == 'string') {
        mediaObj.editFlag = false
        mediaObj.mediaType = data
      } else {
        mediaObj.editFlag = true
        mediaObj.editData = data
        mediaObj.mediaType = data.type
        mediaObj.checkAuthByMediaId = data.id
      }
      mediaObj.changeType = ''
      SM.__proto__.JumpMediaJs()
    },
    // 删除账号
    deleteData (id) {
      this.$confirm(this.$t('lang.i18n_ifDelete'), '', {
        confirmButtonText: this.$t('lang.i18n_confirm'),
        cancelButtonText: this.$t('lang.i18n_quite'),
        type: '',
        center: true,
        customClass: 'shareConfirm'
      }).then(() => {
        SM.__proto__.delete(id).then(res => {
          if (res.data.errorCode == '0x0') {
            this.$message.success(this.$t('lang.i18n_deleteSuccess'))
            this.getTableList()
          } else {
            this.$message.info(res.data.errorInfo)
          }
        }).catch(() => { })
      }).catch(() => {})
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.getTableList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getTableList()
    },
    handleOptionsChange (val) {
      let arr = []
      if (val.Facebook) {
        arr.push('F')
      }
      if (val.Others) {
        arr.push('O')
      }
      if (val.Vimeo) {
        arr.push('Vimeo')
      }
      if (val.Twitter) {
        arr.push('Twitter')
      }
      if (val.YouTube) {
        arr.push('Y')
      }
      if (val.YiLive) {
        arr.push('Z')
      }
      if (val.Twitch) {
        arr.push('T')
      }
      if (val.Instagram) {
        arr.push('I')
      }
      if (val.KuaiShou) {
        arr.push('K')
      }
      this.search_type = arr.length == 0 ? 'NULL' : arr.join(',')
      this.currentPage = 1
      this.getTableList()
    }
  },
  components: {
    Pagination,
    // MultipleSelect,
    SearchInput,
    DropMenuSelect
  },
  beforeDestroy () {
    if (this.upDataInterval) clearInterval(this.upDataInterval)
  }
}
</script>
<style lang="less" scoped>
.SocialAccount {
  text-align: left;
  margin-top: 26px;
  .searchBox {
    width: 100%;
    .searchInput {
      display: inline-block;
      width: 300px;
      height: 32px;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #33ab4f;
      }
    }
    .box {
      width: 160px;
      display: inline-block;
      margin: 0 15px;
      position: relative;
      &.set {
        height: 36px;
        line-height: 36px;
      }
      &.width200 {
        width: 200px;
        margin-right: 20px;
      }
      ::v-deep .el-dropdown {
        width: 100%;
        height: 32px;
      }
      ::v-deep.el-dropdown .el-button.el-button--primary {
        padding: 9px 20px;
      }
    }
    .typebox {
      width: 160px;
    }
    .multiple_select {
      margin: 0 20px;
      width: 202px;
      ::v-deep .dropdownDefault {
        border-radius: 4px;
        height: 35px;
        line-height: 35px;
        background: #444;
        .i-dropdown-arrow {
          top: 14px;
        }
      }
      ::v-deep .dropdown_menu {
        top: 38px;
        border-radius: 4px;
        .is-checked .el-checkbox__inner {
          background: #fff;
        }
        .el-checkbox__inner::after {
          border-width: 1px;
          top: 1px;
          left: 5px;
        }
        .el-checkbox__inner {
          background: none;
          border: 1px solid #dcdfe6;
          height: 15px;
          width: 15px;
        }
      }
    }
    .icon_plus {
      display: inline-block;
      margin-left: 5px;
    }
  }
  .listTable {
    min-height: 600px;
    .wesite_logo {
      height: 32px;
      width: 32px;
    }
  }
  ::v-deep .el-table:before {
    height: 0;
  }
}
</style>
